import * as React from 'react';
import {Text} from 'wix-ui-tpa';
import {classes, st} from './TotalRow.st.css';
import {TotalsDataHook} from '../../Totals';

export interface TotalRowProps {
  title: string;
  value: string;
  className?: string;
  secondaryValue?: string;
}

export const TotalRow = ({title, value, className, secondaryValue}: TotalRowProps) => {
  return (
    <tr data-hook={TotalsDataHook.TotalsRow} className={st(classes.root, className)}>
      <td className={classes.labelTextAlign}>
        <Text className={classes.totalTitleText} data-hook={TotalsDataHook.TotalsRowTitle}>
          {title}
        </Text>
      </td>
      <td className={classes.textAlign}>
        <Text className={classes.totalTitleValue} data-hook={TotalsDataHook.TotalsRowValue}>
          {value}
        </Text>
        {secondaryValue ? (
          <Text className={classes.secondaryValue} data-hook={TotalsDataHook.TotalsRowSecondaryValue}>
            {` ${secondaryValue}`}
          </Text>
        ) : null}
      </td>
    </tr>
  );
};

TotalRow.displayName = 'Totals.TotalRow';
