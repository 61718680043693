import {ThankYouPageStore} from '../domain/stores/ThankYouPageStore';
import {NavigationStore} from '../domain/stores/NavigationStore';
import {
  SubscriptionFrequency as SubscriptionFrequencyOld,
  GetOrderForTypQuery,
  PaymentOptionType,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/graphql/queries-schema';
import {ISettingsParams} from '../components/thankYouPage/settingsParams';
import {StyleParam} from '../components/thankYouPage/constants';
import {
  AdditionalFee,
  CatalogReference,
  OrderItemAction,
  OrderItemRenderingConfig,
  SelectedMembership,
} from '@wix/wixstores-graphql-schema-node';
import {ExtendedFieldsValue} from '@wix/wixstores-client-storefront-sdk';

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export type IControllerProps = {
  thankYouPageStore: Awaited<ReturnType<ThankYouPageStore['toProps']>>;
  navigationStore: Awaited<ReturnType<NavigationStore['toProps']>>;
  ssrError: boolean;
  skipRender: boolean;
};

export type IMedialDimensions = {
  width: number;
  height: number;
};

export type IThankYouPageSettings = {
  booleans: {};
};

export type PickupAddress = {
  addressLine: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
};

export type ShippingAddress = {
  country: string | null;
  subdivision: string | null;
  fullName: string | null;
  addressLine1?: string | null;
  addressLine2: string | null;
  addressLine: string | null;
  city: string | null;
  zipCode: string | null;
  phone: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  company: string | null;
  addressesServiceId?: string | null;
};

export type BillingAddress = {
  country: string | null;
  subdivision: string | null;
  fullName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine: string | null;
  city: string | null;
  zipCode: string | null;
  phoneNumber: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  company: string | null;
  addressesServiceId: string | null;
};

export type StorePickup = {
  title: string;
  time: string;
  instructions: string;
  address: PickupAddress;
};
export type TrackPurchaseParams = {
  origin: string;
  id: number;
  buyerId: string;
  buyerMail: string;
  coupon: string;
  currency: string;
  orderId: string;
  revenue: number;
  shipping: number;
  tax: number;
  contents: {
    id: string;
    sku: string;
    name: string;
    price: number;
    quantity: number;
    currency: string;
    category: string;
    productType?: string;
    catalogID?: string;
  }[];
  userData?: {
    email: string;
    phone: string;
    first_name: string;
    last_name: string;
    address: string;
    city: string;
    region: string;
    postal_code: string;
    country: string;
  };
};

export type Option = {
  title: string;
  isFreeText: boolean;
  description: string;
};

export type Media = {
  altText: string;
  mediaType: string;
  url: string;
  height: number;
  width: number;
};

export type Item = {
  productId: string;
  sku: string;
  catalogReference?: CatalogReference;
  name: string;
  price: number;
  quantity: number;
  productType: string;
  digitalFileLink?: string;
  paymentOption?: PaymentOptionType;
  media: Media;
  options: Option[];
  formattedPrice: string;
  formattedTotal: string;
  lineItemPrice?: {
    formattedAmount: string;
  };
  actions?: OrderItemAction[];
  renderingConfig?: OrderItemRenderingConfig;
  formattedDepositAmount?: string;
  selectedMembership?: SelectedMembership;
  subscriptionInfo?: SubscriptionInfo;
};

export type IOrderModel = {
  buyerName: string;
  buyerMail: string;
  buyerNote?: string | null;
  buyerId: string;
  orderId: number;
  totalPrice: number;
  extendedFields: ExtendedFieldsValue | null;
  formattedTotalPrice: string;
  formattedTaxPrice: string;
  formattedShippingPrice: string;
  formattedSubTotalPrice: string;
  formattedDiscount: string;
  formattedTotalGiftCardAmount: string;
  hasGiftCard: boolean;
  totalGiftCardAmount: number;
  formattedTotalPaid?: string;
  formattedTotalBalance?: string;
  shippingPrice: number;
  taxPrice: number;
  paymentMethod: string;
  payments?: GetOrderForTypQuery['order']['transactions']['payments'];
  paymentStatus: string;
  billingAddress?: BillingAddress;
  shippingAddress: ShippingAddress;
  hasShippingCountryNameTranslation(): boolean;
  deliveryType: string;
  deliveryTime: string;
  storePickup: StorePickup;
  couponCode: string;
  lineItems?: Item[];
  createdDate: number;
  cartId: string;
  checkoutId: string;
  isValid: boolean;
  subscriptionFrequency: SubscriptionFrequencyOld;
  subscriptionDuration: number;
  subscriptionName: string;
  subscriptionInterval: number;
  additionalFees?: Partial<AdditionalFee>[];
  formattedAdditionalFeesPrice?: string;
  additionalFeesPrice?: number;
  subscriptionInfo?: SubscriptionInfo;
};

export enum PaymentStatus {
  Paid = 'paid',
  NotPaid = 'notPaid',
  PartiallyPaid = 'partiallyPaid',
}

export enum PaymentMethod {
  Offline = 'offline',
  Online = 'online',
  Stripe = 'Stripe',
}

export type ITYPStyleSettings = {
  booleans: {
    [StyleParam.ShowOrderNumber]: boolean;
    [StyleParam.ShowImage]: boolean;
    [StyleParam.ShowContinueBrowsingLink]: boolean;
  };
};

export type ICartPublicData = Partial<{[k in keyof ISettingsParams]: string | {}}>;

export interface SubscriptionInfo {
  cycleNumber?: number;
  id?: string;
  subscriptionOptionDescription?: string;
  subscriptionOptionTitle?: string;
  subscriptionSettings?: SubscriptionInfoSettings;
}

export interface SubscriptionInfoSettings {
  frequency?: SubscriptionFrequency;
  interval?: number;
  billingCycles?: number;
  autoRenewal?: boolean;
  enableCustomerCancellation?: boolean;
  startDate?: string;
  freeTrialPeriod?: FreeTrialPeriod;
}

export interface FreeTrialPeriod {
  interval: number;
  frequency: SubscriptionFrequency;
}

export enum SubscriptionFrequency {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  UNDEFINED = 'UNDEFINED',
}
