import * as React from 'react';
import {Divider} from 'wix-ui-tpa';
import {classes, st} from './SummeryDivider.st.css';
import {TotalsDataHook} from '../../Totals';

export interface SummeryDividerProps {
  className?: string;
}

export const SummeryDivider = ({className}: SummeryDividerProps) => (
  <Divider className={st(classes.root, className)} data-hook={TotalsDataHook.SummaryDivider} />
);

SummeryDivider.displayName = 'Totals.SummeryDivider';
