import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {BuyerNoteSection} from './BuyerNoteSection/BuyerNoteSection';
import * as s from './TotalsSection.scss';
import {classes} from './TotalsSectionOverride.st.css';
import {useTranslation} from '@wix/yoshi-flow-editor';
import settingsParams from '../../../settingsParams';
import {Divider} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../LocaleKeysProvider';
import {shorten} from './utils';
import {shouldShowShipping} from '../Layout/utils';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';
import {getSubscriptionDetailsTranslationKey} from '../ProductLineItemsSection/utils';
import {Totals} from './Subtotals/Totals';

export enum TotalsSectionDataHook {
  root = 'TotalsSectionDataHook.root',
  buyerNoteWrapper = 'TotalsSectionDataHook.buyerNoteWrapper',
  totalsWrapper = 'TotalsSectionDataHook.totalsWrapper',
  paymentStatus = 'TotalsSectionDataHook.paymentStatus',
  offlinePaymentStatus = 'TotalsSectionDataHook.offlinePaymentStatus',
  buyerNoteLabel = 'TotalsSectionDataHook.buyerNoteLabel',
  buyerNote = 'TotalsSectionDataHook.buyerNote',
  additionalFeesRoot = 'TotalsSectionDataHook.additionalFeesRoot',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function TotalsSection() {
  const {t} = useTranslation();
  const localeKeys = useLocaleKeys();
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const {
    isOfflinePayment,
    formattedTotalsBalance,
    formattedTotalsPaid,
    formattedTaxPrice,
    formattedShippingPrice,
    formattedSubTotalPrice,
    formattedTotalPrice,
    formattedDiscount,
    couponCode,
    isSubscription,
    subscriptionFrequency,
    subscriptionInterval,
    isPickupSelected,
    shippingPrice,
    formattedTotalGiftCardAmount,
    hasGiftCard,
    totalGiftCardAmount,
    taxName,
    address: shippingAddress,
    pickupAddress,
    shouldDisplayAdditionalFees,
    additionalFees,
    useNewSubscriptionView,
    useProductLineItemFromTYP,
  } = useControllerProps().thankYouPageStore;

  const showShipping = shouldShowShipping({isPickupSelected, pickupAddress, shippingAddress});
  const shouldDisplayFrequencyUnderTotals = isSubscription && (!useNewSubscriptionView || !useProductLineItemFromTYP);

  const getSubscriptionDetails = () => {
    const translationKey = getSubscriptionDetailsTranslationKey(subscriptionInterval, subscriptionFrequency);
    const translation = t(translationKey, {
      numberOfFrequencyUnits: subscriptionInterval,
    });
    return `/ ${translation}`;
  };

  const getTotalTitle = () => {
    return (
      getSettingsWithDefaults(settingsParams.THANK_YOU_PAGE_TOTAL_COST_LABEL) ?? localeKeys.thankYouPage.total.label()
    );
  };

  const getShippingTitle = () => {
    return isPickupSelected ? localeKeys.thankYouPage.pickup.label() : localeKeys.thankYouPage.shipping.label();
  };

  const getShippingValue = () => {
    return shippingPrice === 0 ? localeKeys.thankYouPage.deliveryMethod.free.label() : formattedShippingPrice;
  };

  const getAdditionalFees: Function = (): JSX.Element[] => {
    return additionalFees.map((additionalFee) => (
      <Totals.SubtotalRow
        key={additionalFee.code}
        title={additionalFee.name}
        value={additionalFee.convertedFormattedTotalPriceBeforeTax}
        className={classes.mySubtotalRow}
      />
    ));
  };

  return (
    <div className={s.root} data-hook={TotalsSectionDataHook.root}>
      <BuyerNoteSection />
      {
        <div className={s.totals}>
          <div className={s.section} data-hook={TotalsSectionDataHook.totalsWrapper}>
            <Totals>
              <Totals.SubtotalRow
                title={localeKeys.thankYouPage.subtotal.label()}
                value={formattedSubTotalPrice}
                className={classes.mySubtotalRow}
              />
              {shouldDisplayAdditionalFees && getAdditionalFees()}
              {showShipping && (
                <Totals.SubtotalRow
                  title={getShippingTitle()}
                  value={getShippingValue()}
                  className={classes.mySubtotalRow}
                />
              )}
              <Totals.SubtotalRow
                title={taxName || localeKeys.thankYouPage.tax.label()}
                value={formattedTaxPrice}
                className={classes.mySubtotalRow}
              />
              {!!couponCode && (
                <Totals.SubtotalRow
                  title={localeKeys.thankYouPage.promoCode.label({couponCode: shorten(couponCode, 10, '...')})}
                  value={`-${formattedDiscount}`}
                  className={classes.mySubtotalRow}
                />
              )}
              {!!hasGiftCard && !!totalGiftCardAmount && totalGiftCardAmount > 0 && (
                <Totals.SubtotalRow
                  title={localeKeys.thankYouPage.giftCard.label()}
                  value={`-${formattedTotalGiftCardAmount}`}
                  className={classes.mySubtotalRow}
                />
              )}
              <Totals.SummeryDivider className={classes.mySummeryDivider} />
              <Totals.TotalRow
                className={classes.myTotalRow}
                title={getTotalTitle()}
                value={formattedTotalPrice}
                secondaryValue={shouldDisplayFrequencyUnderTotals ? getSubscriptionDetails() : undefined}
              />
            </Totals>
          </div>
          {isOfflinePayment && (
            <div data-hook={TotalsSectionDataHook.offlinePaymentStatus}>
              <Divider className={s.divider} />
              <Totals>
                <Totals.SubtotalRow
                  title={localeKeys.thankYouPage.paid.label()}
                  value={formattedTotalsPaid}
                  className={classes.mySubtotalRow}
                />
                <Totals.SubtotalRow
                  title={localeKeys.thankYouPage.dueLater.label()}
                  value={formattedTotalsBalance}
                  className={classes.mySubtotalRow}
                />
              </Totals>
            </div>
          )}
        </div>
      }
    </div>
  );
}
