import * as React from 'react';
import {SubtotalRow} from './Partials/SubtotalRow/SubtotalRow';
import s from './Totals.scss';
import {TotalRow} from './Partials/TotalRow/TotalRow';
import {SummeryDivider} from './Partials/SummeryDivider/SummeryDivider';

export enum TotalsDataHook {
  TotalsTable = 'totals-table',
  TotalsRow = 'total-row',
  TotalsRowTitle = 'total-row-title',
  TotalsRowValue = 'total-row-value',
  TotalsRowSecondaryValue = 'total-row-secondary-value',
  SubtotalsRow = 'subtotal-row',
  SubtotalsRowTitle = 'subtotal-row-title',
  SubtotalsRowValue = 'subtotal-row-value',
  SummaryDivider = 'summery-divider',
}

export interface TotalsProps {
  children?: JSX.Element[];
}

export const Totals = ({children}: TotalsProps) => {
  const flattenedChildren = children.reduce((accumulator, currentValue) => accumulator.concat(currentValue), []);
  const validChildren = flattenedChildren.filter((child) => child?.type?.displayName);
  const subtotalRows = validChildren.filter((child) => child.type.displayName === Totals.SubtotalRow.displayName);
  const totalRow = validChildren.filter((child) => child.type.displayName === Totals.TotalRow.displayName);
  const summeryDivider = validChildren.filter((child) => child.type.displayName === Totals.SummeryDivider.displayName);

  return (
    <div data-hook={TotalsDataHook.TotalsTable} className={s.totalsWrapper}>
      <table className={s.tableWrapper}>
        <tbody>{subtotalRows}</tbody>
      </table>
      {summeryDivider}
      <table className={s.tableWrapper}>
        <tbody>{totalRow}</tbody>
      </table>
    </div>
  );
};

Totals.displayName = 'Totals';
Totals.SubtotalRow = SubtotalRow;
Totals.TotalRow = TotalRow;
Totals.SummeryDivider = SummeryDivider;
